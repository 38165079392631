

.navigation_bar{
    font-family: 'poppins_light';
    background-color: #ffffff;
    margin: 0px;
    font-size: 1rem;
    color: white;
}

#logo_name{
    font-family: 'righteous_regular';
    font-size: 20px;
    margin: 0;
    padding-left: 5%;
}

#header_main_logo{
    width: 80px;
}


.pagesnav_dropdown_btn{
    background-color: #212121;
}