.MaintenancePage{
    border-radius: 2rem;
}

#maintenance_icon{
    text-align: center;
    display: inline-block;
    width: 100%;
}

#maintenance_text{
    text-align: center;
    display: inline-block;
    font-family: 'tailwind_regular';
    font-size: 2rem;
}