
body {
  --sb-track-color: #ffdce2;
  --sb-thumb-color: #FF8964;
  --sb-size: 8px;
}

body::-webkit-scrollbar {
  width: var(--sb-size)
}

body::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 3px;
}

body::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 3px;
  
}

@supports not selector(::-webkit-scrollbar) {
  body {
    scrollbar-color: var(--sb-thumb-color)
                     var(--sb-track-color);
  }
}






.themed-scroll {
  --sb-track-color: #ffdce2;
  --sb-thumb-color: #FF8964;
  --sb-size: 8px;
}

.themed-scroll::-webkit-scrollbar {
  width: var(--sb-size)
}

.themed-scroll::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 3px;
}

.themed-scroll::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 3px;
  
}

@supports not selector(::-webkit-scrollbar) {
  body {
    scrollbar-color: var(--sb-thumb-color)
                     var(--sb-track-color);
  }
}






@font-face {
  font-family: 'poppins_regular';
  src: url(fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: 'poppins_light';
  src: url(fonts/Poppins/Poppins-Light.ttf);
}


@font-face {
  font-family: 'poppins_bold';
  src: url(fonts/Poppins/Poppins-Bold.ttf);
}


@font-face {
  font-family: 'righteous_regular';
  src: url(fonts/Righteous/Righteous-Regular.ttf);
}

@font-face {
  font-family: 'tailwind_light';
  src: url(fonts/Tailwind_SC_Light.ttf);
}

@font-face {
  font-family: 'tailwind_light';
  src: url(fonts/Tailwind_SC_Light.ttf);
}

@font-face {
  font-family: 'tailwind_regular';
  src: url(fonts/Tailwind_Regular.ttf);
}

@font-face {
  font-family: 'tailwind_black';
  src: url(fonts/Tailwind_Black.ttf);
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  src: url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap);
}

.root_layout{
  letter-spacing: .07rem;
  background-color: rgb(255, 255, 255);
}

a:link{
  text-decoration: none;
}


.text-align-right{
  text-align: right;
}
.text-aligh-left{
  text-align: left;
}
.text-align-center{
  text-align: center;
}

.font-bold{
  font-weight: bold;
}

.App-link {
  color: #000000;
}

.title-font{
  font-family: 'tailwind_light';
}

.page{
  height: 100vh;
}
.upper-page{
  margin-top: 10%;
}
.hall-page{
  height: 70vh;
}
.page-debug{
  height: 100vh;
  background-color: blueviolet;
  font-family: 'tailwind_black';
}
.div-debug{
  background-color: red;
}

.box-shadow {
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072);
}

.sampleDiv{
  height: 40px;
  width: 100%;
  background-color: red;
}




/** Custom classes **/
.primary-button{
  background-color: #FF8964;
  padding: 7px 14px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  font-family: 'poppins_light';
  font-size: 0.8rem;
}



.color-primary{
  color: #FF8964;
}

.color-black{
  color: #181818;
}

.color-gray{
  color: #f90000;
}

.color-white{
  color: white;
}





.bgcolor-black{
  background-color: #131313;
}
.bgcolor-gray{
  background-color: #e5e5e5;
}
.bgcolor-white{
  background-color: rgb(255, 255, 255);
}
.bgcolor-primary{
  background-color: #FF8964;
}





.font-primary{
  font-family: 'poppins_regular';
}

.font-titles{
  font-family: 'righteous_regular';
}

.border-color-primary{
  border-color: #FF8964;
}



.fill-primary {
  fill: #FF8964;
}

.box-shadowed{
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

.box-primary-shadowed{
  box-shadow: 0px 20px 37px -7px rgba(255,101,132,0.3);
}



.hidden{
  display: hidden;
}


.page{
  height: 100vh;
} 



