

.main_image_container{
    max-height: 200px;
    min-height: 200px;
    overflow: hidden;
}

.gallery_image{
    min-height: 230px;
    object-fit: cover;
}

.description_box{
    padding: 2rem;
}

.post_content{
    margin-bottom: 0;
    padding: 2%;
    border-radius: 1rem;
}

.image_timestamp{
    font-size: 0.7rem;
}

#image_title{
    font-size: 4rem;
}

