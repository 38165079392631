#intro_subs{
    margin-top: 25vh;
}
#intro_sub_1{
    font-weight: bold;
    font-size: 35px;
}

#intro_sub_2{
    font-weight: bold;
    font-size: 80px;
    color: #FF6584;
}

#intro_sub_3{
    font-size: 14px;
}

#homepage_illustration{
    position: absolute;
    left: 650px;
    width: 880px;
    z-index: 1;
}


#section-1{
    min-height: 100vh;
}

#section-2{
    margin-top: 25vh;
    min-height: 100vh;
}

#section-3{
    min-height: 100vh;
}

#section-4{
    min-height: 100vh;
}

#skill_description_card{
    background-color: white;
    box-shadow: 0px 20px 37px -7px rgba(255,101,132,0.3);
}