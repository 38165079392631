#message_area{
    margin-top: 5%;
}

#send_msg_btn{
    margin-top: 2%;
}

#email_input_form{
    margin-top: 2%;
}