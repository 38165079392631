.footer{
    height: 400px;
}

#footer-head{
    font-family: 'righteous_regular';
    font-size: 60px;
    color: white
}

.footer_links{
    text-align: center;
}
.footer_link_btn{
    margin-left: 5%;
    padding-top: 40px;

}
